//异形屏底部适配
@mixin special-padding-bottom($origin: 0.1px) {
  padding-bottom: $origin;
  @supports (padding-bottom: calc(#{$origin} + env(safe-area-inset-bottom, 0))) {
    padding-bottom: calc(#{$origin} + constant(safe-area-inset-bottom));
    padding-bottom: calc(#{$origin} + env(safe-area-inset-bottom, 0));
  }
}

@mixin special-fixed-bottom($origin: 0.1px) {
  position: fixed;
  @supports not (bottom: calc(#{$origin} + env(safe-area-inset-bottom, 0))) {
    bottom: $origin;
  }

  @supports (bottom: calc(#{$origin} + env(safe-area-inset-bottom, 0))) {
    bottom: calc(#{$origin} + constant(safe-area-inset-bottom));
    bottom: calc(#{$origin} + env(safe-area-inset-bottom, 0));
  }
}

@mixin special-bottom-action($origin: 0.1px) {
  height: $origin;
  padding-bottom: 0;
  @supports (padding-bottom: env(safe-area-inset-bottom, 0)) {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
}

@mixin panel-motion() {
  &-enter,
  &-appear,
  &-leave {
    &-start {
      transition: none !important;
    }

    &-active {
      transition: all 0.3s;
    }
  }
}
