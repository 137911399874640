.rc-drawer {
  position: fixed;
  // top: 136px; // page-header高度
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 5;
  pointer-events: none;
  // height: calc(100% - 136px);
  outline: none;
  &-inline {
    position: absolute;
  }
  &-mask {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 5;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: auto;
  }
  &-content-wrapper {
    position: absolute;
    z-index: 1050;
    overflow: hidden;
    transition: transform 0.3s;
    &-hidden {
      display: none;
    }
    .rc-drawer-left & {
      top: 0;
      bottom: 0;
      left: 0;
    }
    .rc-drawer-right & {
      top: 0;
      right: 0;
      bottom: 0;
    }
    .rc-drawer-bottom & {
      left: 0;
      right: 0;
      bottom: 0;
    }
    .rc-drawer-top & {
      left: 0;
      right: 0;
      top: 0;
    }
  }

  &-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: transparent;
    pointer-events: auto;
  }
}

.mask-motion {
  &-enter,
  &-appear,
  &-leave {
    &-active {
      transition: all 0.3s;
    }
  }
  &-enter,
  &-appear {
    opacity: 0;

    &-active {
      opacity: 1;
    }
  }
  &-leave {
    opacity: 1;

    &-active {
      opacity: 0;
    }
  }
}

.panel-motion {
  &-left {
    @include panel-motion();
    &-enter,
    &-appear {
      transform: translateX(-100%);

      &-active {
        transform: translateX(0);
      }
    }
    &-leave {
      transform: translateX(0);

      &-active {
        transform: translateX(-100%) !important;
      }
    }
  }

  &-right {
    @include panel-motion();
    &-enter,
    &-appear {
      transform: translateX(100%);

      &-active {
        transform: translateX(0);
      }
    }
    &-leave {
      transform: translateX(0);

      &-active {
        transform: translateX(100%) !important;
      }
    }
  }
  &-bottom {
    @include panel-motion();
    &-enter,
    &-appear {
      transform: translateY(100%);

      &-active {
        transform: translateY(0);
      }
    }
    &-leave {
      transform: translateY(0);

      &-active {
        transform: translateY(100%) !important;
      }
    }
  }

  &-top {
    @include panel-motion();
    &-enter,
    &-appear {
      transform: translateY(-100%);

      &-active {
        transform: translateY(0);
      }
    }
    &-leave {
      transform: translateY(0);

      &-active {
        transform: translateY(-100%) !important;
      }
    }
  }
}
