@import "~@/resources/styles/mixin.scss";
.iconfont {
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xingxing:before {
  content: '\2605';
}
