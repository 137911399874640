@import "~@/resources/styles/mixin.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* 隐藏IPhone的点击效果 */
  .hide-light {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

@import './rc-notification.scss';
@import './rc-rate.scss';
@import './rc-slider.scss';
@import './rc-pagination.scss';
@import 'rc-drawer/assets/index.css';
@import 'rc-dropdown/assets/index.css';
@import './nprogress.scss';
@import './rc-drawer.scss';
@import './rc-trigger.scss';
@import './rc-picker.scss';

html,
body {
  @apply bg-page-bg;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  overflow-wrap: break-word;
}

a,
button,
input,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.categoryHover {
  &:hover ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
  }

  & ::-webkit-scrollbar {
    width: 8px;
  }

  & ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
    box-shadow: inset006pxrgba(0, 0, 0, 0.5);
    background: transparent;
  }
}
